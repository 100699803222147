@font-face {
  font-family: "NeueHelveticaBd";
  src: url("./assets/fonts/HelveticaNeueLTW1G-Bd.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueLTW1G-Bd.woff") format("woff");
}

@font-face {
  font-family: "NeueHelvetica";
  src: url("./assets/fonts/HelveticaNeueLTW1G-Md.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNeueLTW1G-Md.woff") format("woff");
}

body {
  margin: 0;
  font-family: "NeueHelvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.rec.rec-arrow-left {
  position: absolute;
  top: calc(50% - 25px);
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0 10px 10px 0;
  z-index: 10;
  &:hover:enabled,
  &:focus:enabled {
    background: #78797c;
  }
  &:disabled {
    display: none;
  }
}

.outerGrid {
  border-radius: 14px;
  border: solid 1px #e2e3e4;
  padding: 10px 10px 10px 0;;
}

.outerGrid .rec.rec-arrow-left {
  left: 0;
}
.customerLayoutBx {
  .rec.rec-arrow-right {
    right: -20px;
  }
}
.rec.rec-arrow-right {
  position: absolute;
  top: calc(50% - 25px);
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px 0 0 10px;
  &:hover:enabled,
  &:focus:enabled {
    background: #78797c;
  }
  &:disabled {
    display: none;
  }
}

.cxCardBody {
  flex-direction: column;
  padding: 10px;
}

.cardContainerCar {
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  font-family: "Helvetica";
  width: calc(100% - 20px);
  margin-right: 10px;
  &:focus {
    outline: 0 none;
  }
}

.cardContainerCar .rec.rec-arrow-left,
.cardContainerCar .rec.rec-arrow-right {
  display: none;
}

.cardContainerCar .rec.rec-pagination {
  display: flex;
  margin: 0;
}

.cardContainerCar .rec.rec-pagination .rec-dot {
  width: 7px;
  height: 7px;
  cursor: pointer;
  background: rgba(121, 122, 125, 0.3);
  border-radius: 1rem;
  margin: 0;
  margin-right: 10px;
  box-shadow: none;
  &.rec-dot_active {
    background-color: rgba(121, 122, 125, 0.8);
  }
}

.pointer {
  cursor: pointer;
}

.bg-red {
  background-color: #d01214;
}

.bg-green {
  background-color: #4aa707;
}

.bg-grey {
  background-color: #78797c;
}

.text-color-red {
  color: #d01214 !important;
}

.text-color-green {
  color: #4aa707 !important;
}

.rec.rec-pagination {
  display: none;
}
.rec.rec-slider-container {
  margin: 0;
}

.rec.rec-carousel-wrapper {
  position: relative;
}

.ant-spin-dot-item {
  background-color: #d01214 !important;
}
.carouselWrapper {
  .rec-item-wrapper {
    max-width: 100%;
  }
}
.rec-item-wrapper {
  max-width: 250px;
}
.rec-carousel-item{
.rec-item-wrapper{
  justify-content: left;
}
}
.c3-xgrid-focus { display: none; }

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#909090, #909090);
  cursor: pointer;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(#CCCCCC, #CCCCCC);
}
input[type=text] {
  outline: none;
  border: 1px solid #e2e3e4;
}
select {
  outline: none;
  border: 1px solid #e2e3e4;
  height: 30px;
  padding: 5px;
}
input[type=text]:focus{
  border: 1px solid #e2e3e4;
}

.custom-control-input:checked~.custom-control-label::before{
	border-color: #e71316 !important;
	background-color: #e71316 !important;
}
.alert-dismissible .close{
  padding: 0.25rem 0.30rem !important;
  color: #ffffff !important;
  background-color: #000080;
}
