.itemId{
    display:inline-block;
    width:93px;
    text-align: right;
    vertical-align: text-top;
}
.itemName{
    display:inline-block;
    width: 268px;
    vertical-align: text-top;
    padding-left: 5px;
}